import moment from "moment";

export class DateService {
    public formatDate(date: Date | string | null | undefined) {
        if ((date ?? "") == "") {
            return "";
        }
        return moment(new Date(date as any)).format("DD/MM/YYYY");
    }
    public formatTime(date: Date | string | null | undefined) {
        if ((date ?? "") == "") {
            return "";
        }
        return moment(new Date(date as any)).format("h:mm:ss");
    }
}
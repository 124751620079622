
import ConfirmDialog, {
  IConfirmDialog
} from "@/components/common/ConfirmView.vue";
import { PaginatedQuery } from "@/common/contracts/paginated.result";
import VPagination from "@/components/VPagination.vue";
import { StandardState } from "@/store/module-builder";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLock,
  faLockOpen,
  faSpinner,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { defineComponent } from "@vue/runtime-core";
import _ from "lodash";
import { mapState } from "vuex";
import { ref } from "vue";

library.add(faSpinner, faLock, faLockOpen, faTimes);

export default defineComponent({
  name: "ListComponent",
  setup() {
    const confirmDialog = ref<IConfirmDialog>(null as any);
    return {
      confirmDialog
    };
  },
  components: {
    VPagination,
    ConfirmDialog
  },
  props: {
    showEdit: {
      type: Boolean,
      required: false,
      default: true
    },
    filter: {
      type: Function,
      required: false
    },
    resolveState: {
      type: Function,
      required: true
    },
    createEmptyItem: {
      type: Function,
      required: false
    },
    beforeSave: {
      type: Function,
      required: false
    },
    actions: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: false,
      default: "Items"
    },
    itemName: {
      type: String,
      required: false
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 10
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      sortBy: null as string | null,
      sortByDescending: null as boolean | null,
      currentPage: 1,
      currentDropDownPage: 1,
      currentDropDownSearch: "",
      loading: false,
      item: null as any,
      error: "" as any,
      selectedItem: { id: null }
    };
  },
  beforeMount() {
    if (this.createEmptyItem) {
      this.selectedItem = this.createEmptyItem();
    }
  },
  async mounted() {
    this.error = "";
    this.loading = true;
    try {
      this.$store.dispatch(this.actions.getPaged, this.listConfig);
      this.$store.dispatch(this.actions.reset);
      this.error = "";
    } catch (e) {
      this.error = e;
    } finally {
      this.loading = false;
    }
  },
  computed: {
    itemNameComputed: function () {
      if (this.itemName) {
        return this.itemName;
      }
      if (this.name.endsWith("s")) {
        return this.name.substring(0, this.name.length - 1);
      }
      return "";
    },
    nameComputed: function () {
      return this.name ?? "";
    },
    ...mapState({
      items(state) {
        return (this.resolveState(state) as StandardState).items;
      },
      pagination(state) {
        return (this.resolveState(state) as StandardState).pagination;
      }
    }),
    listConfig(): PaginatedQuery {
      const filters = {
        paginationInformation: {
          currentPage: this.currentPage,
          itemsPerPage: this.itemsPerPage
        },
        sortInformation: {
          field: this.sortBy,
          descending: this.sortByDescending
        }
      } as PaginatedQuery;
      if (this.filter) {
        this.filter(filters);
      }
      return filters;
    }
  },
  methods: {
    loadPage(page?: number) {
      if (page != null) {
        this.currentPage = page;
      }
      this.getPaged();
    },
    setSelected(item: any) {
      this.selectedItem = { ...item };
    },
    setSort(field: string) {
      this.sortBy = field;
      this.sortByDescending = !(this.sortByDescending ?? false);
      this.getPaged();
    },
    getPaged() {
      _.debounce(() => {
        this.$store.dispatch(this.actions.reset);
        this.$store.dispatch(this.actions.getPaged, this.listConfig);
      }, 500)();
    },
    async getItem(id, isToggle = false) {
      let item = this.item || { id: "" };

      if (isToggle && (id === "" || item.id === id)) {
        this.$store.dispatch(this.actions.reset);
        return item;
      } else {
        return (await this.$store.dispatch(this.actions.get, { id }))?.data?.result;
      }
    },
    async addItem() {
      if (this.beforeSave) {
        this.beforeSave(this.selectedItem);
      }
      this.$store.dispatch(this.actions.create, this.selectedItem).then((_) => {
        if (_.status === 200) {
          this.unselectItem();
          this.resetPagination();
          this.getPaged();
          this.$store.dispatch(this.actions.reset);
        }
      });
    },
    async updateItem() {
      if (this.beforeSave) {
        this.beforeSave(this.selectedItem);
      }
      this.$store.dispatch(this.actions.update, this.selectedItem).then(() => {
        this.getPaged();
      });
    },
    unselectItem() {
      this.selectedItem = this.createEmptyItem
        ? this.createEmptyItem()
        : ({} as any);
    },
    async deleteItem(name?: string, item: any = null) {
      const ok = await this.confirmDialog.show({
        title: "Delete",
        message: `Are you sure you want to delete ${
          name && typeof name === "string" ? `"${name}"` : "this"
        }?`,
        okButton: "Delete"
      });
      if (ok) {
        this.$store
          .dispatch(this.actions.delete, item ?? this.selectedItem)
          .then(() => {
            this.getPaged();
            this.unselectItem();
          });
      }
    },
    resetPagination() {
      this.currentPage = 1;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch(this.actions.reset);
    next();
  }
});
